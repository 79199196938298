import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ExternalFeedbackComponent } from './feedback/feedback.component';
import { MasterPageComponent } from '../general/master-page/master-page.component';
import { SellerVisaoGeralResponderComponent } from '../seller/responder/responder.component';

const externalRouterConfig: Routes = [
   {
        path: '', component: MasterPageComponent,
        children: [
            { path: ':id', component: SellerVisaoGeralResponderComponent },
            { path: 'feedback/:tipo', component: ExternalFeedbackComponent }
        ]
    }
];

@NgModule({
    imports:
        [
            RouterModule.forChild(externalRouterConfig)
        ],
    exports: [RouterModule]
})
export class ExternalRoutingModule { }

