import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ExternalFeedbackComponent } from './feedback/feedback.component';

import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { ExternalRoutingModule } from './external.route';

@NgModule({
    declarations: [
        ExternalFeedbackComponent
    ],
    imports: [
        CommonModule,
        RouterModule,

        NgxUiLoaderModule,

        ExternalRoutingModule
    ],
    providers: [

    ],
    bootstrap: []
})
export class ExternalModule { }
